body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.icon-input {
  display: flex;
  flex-direction: row;
}

.input-icon {
  font-size: 1.5em;
  margin: 16px;
}

@font-face {
  font-family: "ImpactFont";
  src: local("ImpactFont"),
   url("./fonts/impact.ttf") format("truetype");
  font-weight: bold;
  }